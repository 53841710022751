.shopify-buy-frame{
  top:0 !important;
}

.shopify-buy-frame--toggle{
  display: block !important;
  position: static !important;
  transform: none !important;
  h5{
    font-weight: normal;
    font-size: 1em;
  }
  cursor: pointer;
}

.shopify-buy__cart-toggle__count{
  display: none;
}