.shop-info{
  background-color: black;
  width: calc(100% + 2.5rem);
  margin: -1.25rem -1.25rem 0;
  padding: 1.25rem;
  color: yellow;
  margin-bottom: 1.25rem;
  text-align: center;
  @include font(small);
  @include fs(small);
  font-weight: bold;
}

.products--grid{
  padding-top: 8rem;
  @include mq(md){
    padding-top: 12rem;
  }
}

.products-collection--menu{
  display: flex;
  z-index: 999;
  position: fixed;
  bottom: 1.25rem;
  & > *{
    margin-right: 0.5rem;
  }
  left: 1.25rem;
  a.active{
    color: var(--color-text);
    border-bottom: 1px solid;
    border-color: var(--color-text);
  }
  a:hover{
    border-bottom: 1px solid;
    border-color: var(--color-text);
  }
}