.products--grid{
  display: grid;
  @include mq(md){
    grid-template-columns: repeat(3,1fr);

  }
  grid-template-columns: repeat(1,1fr);
  grid-gap: 7vw;
  @include font(small);
  @include fs(small);
  font-weight: bold;
  padding-bottom: 16.09375vw;
  @include mq(md){
    padding-bottom: 8vw;
  }
}

.product--featured-image{
  position: relative;
  .sold-out{
    display: none;
  }
  &:hover{
    @include notouch(){
      img{
        opacity: 0.5;
      }
      cursor: pointer;
    }
    .sold-out{
      opacity: 1;
      display: block;
      position: absolute;
      z-index: 9;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      
    }
  }
}

.products--grid-item-info{
  display: flex;
  justify-content: space-between;
}