.shopify-buy__product{
  grid-template-columns: repeat(2, 1fr);
}

.pswp img{
  object-fit: cover;
}

.shopify-buy__product__actual-price {
  display: block;
  margin-bottom: 1em;
}