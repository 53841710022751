*::-webkit-scrollbar{
  display: none;
}

*{
  scrollbar-width: none;
}

html, body, main{
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

main{
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

}

.container{
  flex:1;
  @include mq(md){
    padding: 0 7.32%;
  }
  padding: 1.25rem;
}

.text-container{
  padding: 4.2rem 0;
  @include mq(md){
    padding: 8.438rem 10.53% 8.438rem;
  }
}

.text-container h1{
  @include font(title);
  @include fs(title);
  font-weight: normal;
  margin-top: 1.25em;
  margin-bottom: 0;
}

.text-container ol li{
  @include font(title);
  @include fs(title);
}

.text-container p{
  @include font(serif);
  @include fs(serif);
}

.foot-page--logo{
  text-align: center;
  img{
    width: 10rem;
  }
}

.inner-page-logo{
  margin-top: 2.5rem;
  width: 7rem;
  left:50%;
  position: relative;
  transform: translateX(-50%);
}

.color-second{
  color: var(--color-second);
}
.filter-second{
  filter: invert(87%) sepia(90%) saturate(601%) hue-rotate(1deg) brightness(101%) contrast(102%);
}

.show-xs{
  @include mq(md){
    display: none;
  }
}