.gotoshop{
  display: block;
  width: 100%;
  height: 100%;
  @include mq(md){
    pointer-events: none;
  }
}

.background{
  position: fixed;
  height: 100%;
  width: 100%;
  img{
    min-height: 100%;
    min-width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    object-fit: cover;
    object-position: center center;
    cursor: pointer;
  }
}

.thumb-s{
  pointer-events: none;
  display: none;
  position: absolute;
  opacity: 0;
  transition: opacity $transition-duration-default $transition-timing-default;
  &.visible{
    opacity: 1;
    transition: opacity $transition-duration-default $transition-timing-default;
  }
  &.portrait{
    width: 8.5vw;
    min-width: 100px;
  }
  &.landscape{
    width: 12.5vw;
    min-width: 150px;
  }
  @include mq(md){
    display: block;
  }
}

.thumb-m{
  display: none;
  position: absolute;
  opacity: 0;
  transition: opacity $transition-duration-default $transition-timing-default;
  &.visible{
    opacity: 1;
    transition: opacity $transition-duration-default $transition-timing-default;
  }
  &.portrait{
    width: calc(8.5vw * 1.5);
    min-width: 100px;
  }
  &.landscape{
    width: calc(12.5vw * 1.5);
    min-width: 150px;
  }
  @include mq(md){
    display: block;
  }
}