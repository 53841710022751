.text-container .text em{
  @include font(main);
}

.gallery-grid{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-column-gap: 14.609375vw;
  grid-row-gap: 9.375vw;
  padding-bottom: 9.375vw;
  picture.landscape{
    grid-column: span 2;
    padding: 0vw;
    @include mq(md) {
      grid-column: span 2;
      padding: 0 16.09375vw;
    }
  }

  picture.portrait{
    grid-column: span 2;
    padding: 0 7vw;
    @include mq(md){
      grid-column: span 1;
      padding: 0;
    }
  }
  picture.square{
    grid-column: span 2;
    padding: 0 7vw;
    @include mq(md){
      grid-column: span 1;
      padding: 0;
    }
  }
}



