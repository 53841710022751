.detailed-product--grid{
  padding: 0 0em;
  margin-top: 2em;
  @include mq(md){
    margin-top: -5rem;
    display: flex !important;
    grid-template-columns: 60% 40%;
    width: 100%;
    padding-right: 40%;
  }

  h2{
    font-size: 1em;
  }
  span{
    font-size: 1em !important;
  }
  .shopify-buy__product-description{
    font-size: 12px !important;
    line-height: 14px !important;
    @include mq(md){
      font-size: 13px !important;
      line-height: 15px !important;
    }
    *{
      font-size: 12px !important;
      line-height: 14px !important;
      @include mq(md){
        font-size: 13px !important;
        line-height: 15px !important;
      }
    }
  }

  img{
    @include mq(md) {
      margin-bottom: 10rem;
    }
  }

  .portrait{
    @include mq(md){
      padding: 0 10rem;
    }
  }
  .landscape{
    @include mq(md){
      padding: 0 10rem;
    }
  }
}

.visuallyhidden{
  display: none;
}



//——————- PRODUCT INFO ————————— //

.shopify-buy__product{
  @include mq(md){
    margin-top: calc(12rem - 5rem);
  }
  border-top: 2px solid;
  margin-right: 10rem;
  margin-left: 1.25rem;
  margin-bottom: 1.25rem;
  width: calc(100% - 1.25rem *2);
  @include mq(md){
    position: fixed;
    top: 0rem;
    width: calc(40% - calc(1.25rem + 10rem));
    right: 0;
  }
  font-family: 'Helvetica';
  font-weight: bold;
  h2{
    margin: 1em 0;
  }
}

.shopify-buy__product__price{
  display: grid;
  grid-template-columns:  repeat(2, min-content);
  grid-column-gap: 0.5em;
  .shopify-buy__product__compare-price{
    order: -1;
    text-decoration: line-through;
  }
}

.shopify-buy__btn-wrapper{
  width: 50%;
  margin-top: 1em;
  cursor: pointer;
  position: relative;
}

.alert{
  position: absolute;
  width: 200%;
  margin-top: 0.5em;
  font-size: 0.75em;
}
.shopify-buy__btn{
  font-family: inherit;
  font-weight: inherit;
  padding: 0.3em;
  color: var(--color-text);
  border: 2px solid;
  background: transparent;
  width: 100%;
  display: block;
  cursor: pointer;
  &:hover{
    border-color: black;
    background: black;
    color: yellow;
  }
  &.shopify-buy__btn-disabled{
    cursor: default;
    &:hover{
      border-color: inherit !important;
      background: inherit !important;
      color: inherit !important;
    }
  }
}
.shopify-buy__product__variant-selectors{
  display: flex;
  align-items: end;
  grid-column-gap: 1em;
  .shopify-buy__option-select{
    flex: 1;
  }
}




  //——————- SELECT ELEMENTS ————————— //
.shopify-buy__option-select{
  cursor: pointer;
  &:hover{
    > *{
      color: black;
    }
    select{
      color: black;
    }
    .shopify-buy__option-select-wrapper {
      border-bottom: 2px solid black;
    }
  }
}
.shopify-buy__option-select-wrapper {
  border-bottom: 2px solid var(--color-text);
  box-sizing: border-box;
  position: relative;
  background: #fff;
  cursor: pointer !important;
  overflow: hidden;
  vertical-align: bottom; }

.shopify-buy__select-icon {
  cursor: pointer;
  display: none;
  fill: var(--color-text);
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -6px;
  pointer-events: none;
  width: 12px;
  height: 12px;
  vertical-align: middle; }

.shopify-buy__option-select + .shopify-buy__option-select {
  margin-top: 7.5px; }

.shopify-buy__option-select__label {
  display: block;
  position: absolute;
  pointer-events: none;
  padding: 7px 0;
  z-index: 9;
}
.shopify-buy__btn--parent .shopify-buy__option-select__label {
  cursor: pointer; }

.shopify-buy__option-select__select {
  font-size: inherit;
  padding: 7px 0px;
  border: 0;
  width: 100%;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none; }
.shopify-buy__btn--parent .shopify-buy__option-select__select {
  cursor: pointer; }
.shopify-buy__option-select__select::-ms-expand {
  display: none; }

select{
  color: var(--color-text);
  border-width: 2px;
  outline: none;
  font-family: 'Helvetica';
  font-weight: bold;
  padding-left: 50% !important;
  text-align: right;
  cursor: pointer;
}

.pswp__counter{
  display: none;
}



