header {
  position: fixed;
  z-index: 9;
  top: 0;
  padding: 1.25rem;
  width: 100%;
}

.video-active header{
  background-color: transparent !important;
  transition-poperty: background-color;
  transition-delay: 0.3s;
}

nav {
  position: absolute;
  top: 1.25rem;
  @include mq(md) {
    position: static;
    display: grid;
    z-index: 99;
    grid-template-columns: repeat(12, 1fr);
  }

  .site-title {
    position: fixed;
    top: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
    @include mq(md) {
      top: unset;
      left: unset;
      transform: unset;
      position: static;
      grid-column: span 3;
    }

  }

  .nav-inner {
    display: none;
    @include mq(md) {
      grid-column: span 6;
      display: flex;
      justify-content: center;
    }
  }

  #cart {
    position: fixed;
    z-index: 1;
    top: 1.25rem;
    right: 1.25rem;
    @include mq(md) {
      grid-column: span 3;
      text-align: right;
      z-index: auto;
    }
  }
}

.menu-is-active #cart{
  z-index: -1;
  @include mq(md){
    z-index: 1;
  }
}

nav .menu-item .active{
  color: var(--color-text);
}

.menu-item, .submenu-item {
  @include font(title);
  @include fs(title);
  @include mq(md){
    margin: 0 0.25em;
  }
}

.menu-item.serif {
  @include font(serif);
  @include fs(serif);
  @include mq(md) {
    margin: 0 0.25em;
  }
}

.nav-inner .menu-item:nth-of-type(3){
  order: -2;
  .submenu{
    opacity: 1;
    pointer-events: auto;
    max-height: 100% !important;
    height: auto;
  }
  .submenu.opened{
    opacity: 1;
  }
  @include mq(md){
    order: unset;
    .submenu{
      opacity: 0;
      height: 100vh;
      max-height: unset !important;
      pointer-events: none;
    }
    .submenu.opened{
      opacity: 1;
      pointer-events: auto;
    }
  }
}


.submenu {
  opacity: 1;
  padding-left: 1rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  .submenu-item{
    text-transform: capitalize;
    em{
      @include font(serif);
    }
  }
  .submenu-container{
    margin-bottom: 1.25rem;
    position: relative;
  }
  @include mq(md) {
    display: flex;
    flex-direction: column;
    .submenu-empty-area{
      flex: 1;
      background-color: white;
    }
    padding: 5rem 0 0;
    position: absolute;
    max-height: unset;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    opacity: 0;
    transition: all $transition-duration-default $transition-timing-default;
    pointer-events: none;
    .submenu-container{
      margin-bottom: 1.25rem;
    }
  }
}

#home .submenu-empty-area, body[data-template="film"] .submenu-empty-area{
  background-color: transparent;
}

.submenu.opened {
  opacity: 1;
  pointer-events: auto;
  height: 100vh;
  transition: all $transition-duration-default $transition-timing-default;
}

.menu-item:hover:not(.site-title) {
  @include mq(md) {
  border-bottom: 1px solid;

  }
}

// HAS SHOP INFO HEADER

#shop, body[data-template="categories"]{
  nav #cart{
    top: calc(1.25rem + 54px);
  }
  .site-title{
    top: calc(1.25rem + 54px);
    @include mq(md){
      top: unset;
    }
  }
  .submenu{
    @include mq(md){
      padding: calc(5rem + 54px) 0 0;
    }
  }

}


// MOBILE MENU

body[data-template='shop']{
  .menu-toggler{
    position: absolute;
    top: calc(1.25rem + 54px);
    @include mq(md){
      display: none;
      position: relative;
      top: unset;
    }
  }

}

.menu-toggler {
  display: block;
  width: min-content;
  cursor: pointer;
  @include mq(md) {
    display: none;
    position: relative;
    top: unset;
  }
}

.menu-is-active {
    .nav-inner {
      position: fixed;
      left: 0;
      top: 0;
      padding: 1.25rem;
      display: flex;
      flex-direction: column;
      background: white;
      width: 100%;
      height: 100%;
    }
    .menu-closer {
      display: block;
      position: absolute;
      cursor: pointer;
      top: 1.25rem;
      right: 1.25rem;
      @include font(serif);
      font-size: 3em;
    }
}

.menu-closer{
  display: none;
}

.nav-footer-menu{
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  display: flex;
  .menu-item:nth-of-type(3){
    order: unset;
    margin-right: auto;
  }
  @include mq(md){
    display: none !important;
  }

}