body[data-template="film"]{
  background: black;
}

.video-container{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  &.hide{
    opacity: 0 !important;
  }
}

.video-wrapper{
  width: 100vw;
  height: 1000vw;
  position: relative;
  top:0;
  pointer-events: none;
}
.video-background {
  background: #000;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 0;
  position: absolute;
  overflow: hidden;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  height: 100%;
  @include mq(md){
    width: 105%;
  }
}
#vidtop-content {
  top: 0;
  color: #fff;
}

.video-poster{
  display: none;
}

.btn-container{
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1.25rem;;
  left: 0;
  display: flex;
  button{
    @include fs(title);
    background: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    padding: 0;
    margin-right: 0.5rem;
    @include mq(md){
      @include fs(main);
    }
    &:hover{
    }
    &.active{
      border-bottom: 1px solid;
      border-color: var(--color-second);
      color: var(--color-second);
    }
    &:last-of-type{
      margin-left: auto;
    }
  }
}