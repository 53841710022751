.text.contact-text{
  text-align: center;
  p{
    @include font(title);
    @include fs(title);
    margin-bottom: 1.25em;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
}