#home .footer{
  position: fixed;
}

.footer{
  padding: 1.25rem;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
  z-index: 99;
  @include mq(md){
    grid-template-columns: repeat(3,33.333%);
    display: grid;
    align-items: end;
  }
}

.footer-logo{
  display: flex;
  justify-content: center;
  img{
    width: 4rem;
    @include mq(md){
      width: 5rem;
    }

  }
}

.video-trigger{
  @include fs(title);
  span{
    display: none;
  }
  @include mq(md){
    @include fs(main);
    span{
      display: inline;
      text-transform: capitalize;
    }
  }
}

.footer-menu, .nav-footer-menu{
  justify-content: end;
  .menu-item{
    display: inline-block;
    border-bottom: 1px solid transparent;
    @include fs(main);
    margin: 0 0.25em;
    &.active{
      border-bottom: 1px solid;
      border-color: var(--color-text);
      color: var(--color-text);
    }
  }
}

.footer-menu{
  display: none;
  @include mq(md){
    display: flex;
  }
}

.nav-footer-menu .menu-item{
  @include fs(title);
}

.footer-video-container{
  overflow: hidden;
  position: fixed;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: black;
  transform: translateY(100%);
  transition: transform 0.4s $transition-timing-default;
  &.active{
    display: flex;
    transform: translateY(0);

  }
}

#shop, body[data-template="shopify.collection"]{
  .footer .video-trigger{
    visibility: hidden;
    pointer-events: none;
  }
}

 body[data-template="categories"]{
  .footer .video-trigger{
    visibility: hidden;
    pointer-events: none;
  }
}