#cgv .text-container{
  p, h1, li{
    font-family: "Helvetica";
    font-weight: bold;
    font-size: 1em;
    line-height: 1.2em;
    text-align: left;
    @include mq(md){
      font-size: 0.8em;
    }
  }
  h1{
    margin-top: 1em;
    margin-bottom: 1em;
  }
  li{
    list-style-type: disc;
    margin-left: 1em;
  }
}